@import '/src/style/main.scss';

.header {
  display: flex;
  flex-direction: column;
  height: 100%;
  font-size: $font-size;

  @include mobile {
    font-size: $mobile-font-size;
    height: 100svh;
  }

  &__text {
    display: inline;
    font-family: 'avara-bold';
    font-size: 3.53vw;
    letter-spacing: 0.2px;
    line-height: 1.2em;

    @include mobile {
      font-size: 3vh;
      letter-spacing: 0.09px;
      line-height: 1.3em;
      margin-bottom: $mobile-intro-margin;
    }

    &-italic {
      display: inline;
      font-family: 'avara-bold-italic';
    }
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;

    @include mobile {
      height: auto;
    }
  }

  &__info {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: absolute;
    left: 0;
    right: 0;
    top: calc(50% - 31px);

    @include mobile {
      position: static;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  &__name {
    @include mobile {
      margin-bottom: 8px;
    }
  }

  &__expirience {
    position: absolute;
    left: calc(50% - 167px);
    text-align: center;

    @include tablet {
      width: 200px;
    }

    @include mobile {
      position: relative;
      left: 0;
      margin-bottom: $mobile-intro-margin;
    }
  }

  &__location {
    margin-bottom: 8px;
  }

  &__footer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @include mobile {
      display: flex;
      height: 7.5vh;
      flex-direction: column-reverse;
      justify-content: space-between;
      align-items: center;
      position: static;
    }
  }

  &__portfolio {
    position: absolute;
    left: calc(50% - 37px);
    text-align: center;
    cursor: pointer;
    z-index: 10000;

    @include mobile {
      position: static;
    }
  }

  &__empty {
    width: 14%;

    @include mobile {
      display: none;
    }
  }
}
