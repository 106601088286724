@import "/src/style/vars.scss";

@mixin transition {
  transition: 0.3s ease;
}

@mixin onhover {
  &:hover {
    @include transition;

    font-family: $font-italic;
  }
}

@mixin mobile {
  @media (max-width: #{$mobile-width}) {
    @content; 
  }
}

@mixin tablet {
  @media (max-width: #{$tablet-width}) {
    @content; 
  }
}

@mixin large {
  @media (min-width: #{$large-width}) {
    @content; 
  }
}

@mixin super-large {
  @media (min-width: #{$super-large-width}) {
    @content; 
  }
}

@mixin super-small {
  @media (max-width: #{$super-small-width}) {
    @content; 
  }
}
