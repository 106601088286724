$main-font-color: #000;
$moto-font-color: #e8e8e8;

$font: 'edu-diatype-regular';
$font-italic: 'edu-diatype-italic';
$font-medium: 'edu-diatype-medium';
$font-size: 16px;
$font-size-small: 14px;
$font-links: 11px;
$mobile-font-size: 13px;
$carousel-mobile-font: 13px;
$padding-size: 20px;
$mobile-padding-size: 15px;
$mobile-intro-margin: 7%;

$mobile-width: 640px;
$tablet-width: 900px;
$large-width: 1500px;
$super-small-width: 375px;
$super-large-width: 1700px;
