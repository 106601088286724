html,
body,
h1,
h2,
h3,
nav,
header,
img,
div,
p,
a,
button,
option,
ul,
li,
span,
section {
  padding: 0;
  margin: 0;
}
