@import './vars';
@import './mixins.scss';

* {
  box-sizing: border-box;
}

body {
  font-family: $font;
  letter-spacing: 0.16px;
  font-size: 20px;
  transform: translate3d(0px, 0px, 0px);
  transition: all 700ms ease;
  box-sizing: border-box;
  background-color: white;

  @include mobile {
    font-family: $font-medium;
  }
}
