@import '/src/style/main.scss';

.grid {
  display: grid;
  position: relative;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: 1fr 1fr 1fr;
  top: -85vh;
}

.grid-item--1 {
  grid-column: 1;
  grid-row: 2;
}

.grid-item--2 {
  grid-column: 2;
  grid-row: 3;
}

.grid-item--3 {
  grid-column: 2;
  grid-row: 1;
}

.grid-item--4 {
  grid-column: 3;
  grid-row: 2;
}

.grid-item--5 {
  grid-column: 4;
  grid-row: 3;
}

.grid-item--5 {
  grid-column: 4;
  grid-row: 3;
}

.grid-item--6 {
  grid-column: 4;
  grid-row: 1;
}

.grid-item--7 {
  grid-column: 5;
  grid-row: 2;
}

.grid-item--8 {
  grid-column: 5;
  grid-row: 1;
}

.grid-item--9 {
  grid-column: 6;
  grid-row: 2;
}

.grid-item--10 {
  grid-column: 6;
  grid-row: 3;
}

.grid-item--11 {
  grid-column: 7;
  grid-row: 2;
}
