@import '/src/style/main.scss';

.picture {
  &__img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    opacity: 0;
    transition: opacity 0.3s ease;

    &--active {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      transition: opacity 0.3s ease;
      opacity: 1;
    }
  }

  &__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}
