@import '/src/style/main.scss';

.intro {
  height: 100vh;
  padding: $padding-size 0;
  scroll-snap-align: center;
  position: relative;
  // background-image: url('../../assets/name.svg');
  // background-repeat: no-repeat;
  // background-size: contain;
  // background-position: 10% 90%;

  @include mobile {
    // background-image: url('../../assets/name-mobile3.svg');
    height: 100%;
    padding: $mobile-padding-size 0 0;
    // padding-bottom: 80vh;
  }

  // &__header {
  //   display: flex;
  //   gap: calc(50vw - 160px);
  //   margin-bottom: 150px;

  //   @include mobile {
  //     margin-bottom: 20px;
  //   }
  // }

  // &__name {
  //   min-width: 123px;
  //   margin-bottom: 12px;
  //   color: $main-font-color;
  //   font-size: $font-size;

  //   @include mobile {
  //     font-size: 17px;
  //   }
  // }

  // &__description {
  //   color: $main-font-color;
  //   font-size: $font-size;
  //   line-height: 24px;

  //   @include mobile {
  //     font-size: $mobile-font-size;
  //     line-height: 20px;
  //   }
  // }

  // &__description-location {
  //   @include mobile {
  //     display: none;
  //   }
  // }

  // &__description-moto {
  //   @include mobile {
  //     display: none;
  //   }
  // }
}
