@import "/src/style/main.scss";

.cursor {
  background-image: url('../../assets/cursor.svg');
  background-repeat: no-repeat;
  position: absolute;
  width: 20px;
  height: 15px;
  mix-blend-mode: difference;
  z-index: 100000;
  pointer-events: none;

  &--left {
    transform: rotate(180deg);
  }

  @include mobile {
    display: none;
  }
}
