@font-face {
  font-family: 'edu-diatype-medium';
  src: url('../assets/fonts/EduDiatype-Medium.woff');
}

@font-face {
  font-family: 'edu-diatype-italic';
  src: url('../assets/fonts/EduDiatype-MediumItalic.woff2');
}

@font-face {
  font-family: 'edu-diatype-regular';
  src: url('../assets/fonts/ABCDiatype-Regular-Trial.woff2');
}

@font-face {
  font-family: 'avara-bold';
  src: url('../assets/fonts/Avara-Bold_web.woff2');
}
@font-face {
  font-family: 'avara-bold-italic';
  src: url('../assets/fonts/Avara-Bold_Italic_web.woff2');
}
