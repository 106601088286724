@import '/src/style/main.scss';

.footer {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: space-between;
  scroll-snap-align: center;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  padding-bottom: 20px;

  @include mobile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    padding-bottom: 15px;
  }

  &__info {
    // display: grid;
    // grid-template-columns: max-content max-content max-content max-content auto;
    // align-items: center;
    width: 50%;
    display: flex;
    justify-content: space-between;

    @include mobile {
      width: 100%;
      font-size: 13px;
      grid-template-columns: max-content max-content max-content max-content auto;
    }

    @include super-small {
      grid-template-columns: max-content max-content max-content auto;
    }
  }

  &__logo--mobile {
    display: none;

    @include mobile {
      padding-top: 70px;
      padding-bottom: 70px;
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &__logo {
    width: 45%;
    height: auto;
    object-fit: cover;
    float: 'right';

    @include mobile {
      display: none;
      padding-top: 20px;
      position: absolute;
      width: 100%;
      top: 0;
      float: none;
    }
  }

  &__contact {
    display: flex;
    align-items: center;
    margin-right: 10px;
    color: $main-font-color;
    font-size: 20px;

    @include mobile {
      font-size: $mobile-font-size;
    }

    @include super-small {
      display: none;
    }
  }

  &__email {
    text-decoration: underline;
    color: $main-font-color;
    font-size: 20px;
    cursor: pointer;
    margin-right: 10px;

    @include mobile {
      font-size: $mobile-font-size;
    }
  }

  &__instagram {
    color: $main-font-color;
    text-decoration: underline;
    font-size: 20px;
    cursor: pointer;
    margin-right: 40px;

    @include tablet {
      margin-right: 10px;
    }

    @include mobile {
      font-size: $mobile-font-size;
    }

    &:visited {
      color: $main-font-color;
    }
  }

  &__up {
    color: $main-font-color;
    font-size: $font-size;

    cursor: pointer;

    @include mobile {
      font-size: $mobile-font-size;
      justify-self: flex-end;
      text-align: right;
    }
  }
}
