@import '/src/style/main';

.carousel {
  display: flex;
  flex-direction: column;
  height: 100vh;
  scroll-snap-align: center;
  color: $main-font-color;
  padding: 20px 0;

  @include mobile {
    height: 100%;
    padding: $mobile-padding-size 0;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 12px;

    @include mobile {
      position: relative;
    }
  }

  &__title {
    width: calc(100% / 3);

    display: flex;
    flex-direction: column;

    @include mobile {
      width: 37%;
    }
  }

  &__title-paragraph {
    display: block;
    max-width: 230px;
    font-size: $font-size;

    @include mobile {
      font-size: $carousel-mobile-font;
    }
  }

  &__counter {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: $font-size;

    @include mobile {
      font-size: $carousel-mobile-font;
    }

    div {
      display: inline-block;
      font-size: $font-size;

      @include mobile {
        font-size: $carousel-mobile-font;
      }
    }
  }

  &__current-photo {
    display: inline-block;
    text-align: center;
    font-size: $font-size;
  }

  &__slash {
    margin: 0 2px;
    font-size: $font-size;
  }

  &__links {
    width: calc(100% / 3);
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }

  &__link {
    text-decoration: underline;
    cursor: pointer;
    display: flex;
    font-size: $font-size-small;
    color: black;

    @include mobile {
      font-size: $font-links;

      &:visited {
        color: $main-font-color;
      }
    }

    &:visited {
      color: $main-font-color;
    }
  }

  &__img-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 2px;
    cursor: none;

    @include mobile {
      cursor: pointer;
      min-height: 400px;
      aspect-ratio: 720/824;
    }
  }
}
