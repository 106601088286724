@import '/src/style/main.scss';

.preview-link {
  display: block;
  position: relative;
  width: 90%;
  aspect-ratio: 720 /834;
  height: auto;
  opacity: 0;
  transition: 0.5s ease;
  z-index: 1000;
  cursor: pointer;

  @include mobile {
    display: none;
  }

  &:hover {
    opacity: 1;
    transition: 0.5s ease;
  }

  img {
    width: 100%;
    height: 100%;
  }
}
