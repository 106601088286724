@import './style/main';

.app {
  padding: 0 20px;
  height: 100vh;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;

  @include mobile {
    padding: 0 15px;
    height: 100%;
    overflow-y: unset;
    scroll-snap-type: unset;
  }
}
