@import '/src/style/main.scss';

.message {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.876);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;

  &__text {
    color: $main-font-color;
  }
}
