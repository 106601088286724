@import '/src/style/main.scss';

.contacts {
  position: relative;
  display: grid;
  grid-template-columns: max-content max-content max-content max-content;
  align-items: center;
  z-index: 10000;
  font-size: $font-size;

  @include mobile {
    font-size: $mobile-font-size;
  }

  &__title {
    display: flex;
    align-items: center;
    margin-right: 10px;
    color: $main-font-color;
  }

  &__email {
    text-decoration: underline;
    color: $main-font-color;
    cursor: pointer;
    margin-right: 10px;
  }

  &__instagram {
    color: $main-font-color;
    text-decoration: underline;
    cursor: pointer;
    margin-right: 40px;

    @include tablet {
      margin-right: 10px;
    }

    &:visited {
      color: $main-font-color;
    }

    &--behance {
      margin-right: 0;
    }
  }
}
