@import '/src/style/main.scss';

.video {
  position: absolute;
  background-color: black;
  padding: 80px 30px;
  object-fit: contain;
  object-position: center;
  transition: 0.3s ease;
  opacity: 1;

  &__play-button {
    width: 100px;
    height: 100px;
    position: absolute;
    left: calc(50% - 50px);
    top: calc(50% - 50px);
    transition: opacity 0.5 ease;
    opacity: 1;

    &--visible {
      transition: opacity 0.5 ease;
      opacity: 0;
    }
  }

  &--alpha {
    padding: 0;
    object-fit: cover;
  }

  &--notvisible {
    opacity: 0;
    transition: opacity 0.5s ease;
  }
}
